.TwoResults {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.ThreeResults {
  display: flex;
  padding: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .ThreeResults {
    animation: Fade-In-Three-Result 1s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .TwoResults {
    animation: Fade-In-Two-Result 1s linear;
  }
}

@keyframes Fade-In-Three-Result {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Fade-In-Two-Result {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ExactResult {
  padding: 20px;
  padding-bottom: 30px;
  font-weight: bold;
  font-size: calc(15px + 7vmin);
  height: 54px;
}

.Result {
  padding: 10px;
}
