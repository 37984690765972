.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.App-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: calc(10px + 7vmin);
  color: white;
}

.InputSection {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ResultSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
}

.footer-text {
  color: white;
}

.App-footer {
  background-color: #282c34;
  font-size: small;
  flex-direction: column;
  align-content: flex-end;
  padding-top: 2vh;
  padding-bottom: 2vh;
  flex: 1;
}

.readSpeed {
  background-color: #282c34;
  color: white;
  border-color: rgb(200, 255, 0);
  border-radius: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(10px + 4vmin);
  font-weight: bold;
  text-align: center;
}

.songBpm {
  background-color: #282c34;
  color: white;
  border-color: red;
  border-radius: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(10px + 4vmin);
  font-weight: bold;
  text-align: center;
}

.GameSelect {
  background-color: #282c34;
  color: white;
  border-color: rgb(112, 112, 233);
  border-radius: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  text-align: center;
}

.GameForm {
  background-color: #282c34;
  color: white;
  border-color: rgb(112, 112, 233);
  border-radius: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  text-align: center;
}
